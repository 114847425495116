export const environment = {
  production: true,
  conversationHistoryUrl: 'https://api.agis.inator.cloud/bot/session/conversation',
  aiAdviceUrl: 'https://api.agis.inator.cloud/agent/chat',
  aiAdviceAutocompleteUrl: 'https://api.agis.inator.cloud/agent/autocomplete',
  // TODO: Use cloudfront URL
  // apiUrl: 'https://engage.agis.inator.cloud/api/adm',
  apiUrl: 'https://k1481s6ytb.execute-api.eu-west-1.amazonaws.com/prod/api/adm',
  apiKey: 'LgeljLAbGA9ndrWPtcv918zIlLCrAkId1mOtnS6G',
  googleMapApiKey: 'AIzaSyAD7v9KMq7NiT_6dP7AvPPVMFFtd5wL4FE',
  cacheApi: ['/cannedresponses'],
  autoRefreshDashboard: false,

  amplifyConfig: {
    aws_project_region: 'eu-west-1',
    aws_cognito_identity_pool_id: 'eu-west-1:18e9e55f-936a-4a11-b9fb-3c4cee75e79b',
    aws_cognito_region: 'eu-west-1',
    aws_user_pools_id: 'eu-west-1_JryGE8h53',
    aws_user_pools_web_client_id: 'r5opfo5e57erilhmohsh6c57g',
    oauth: {},
    aws_appsync_graphqlEndpoint: 'https://topzil4bgnhmlbwupnfk533mye.appsync-api.eu-west-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-west-1',
    aws_appsync_authenticationType: 'AWS_IAM',
    Storage: {
      AWSS3: {
        // TODO: Add functionality to use another domain name
        bucket: 'asset.engage.agis.inator.cloud',
        region: 'eu-west-1'
      }
    }
  },

  internalChatConfig: {
    appsync: {
      url: 'https://4oauav5kzndz3j7e3t7lnoreqy.appsync-api.eu-west-1.amazonaws.com/graphql',
      region: 'eu-west-1'
    },
    s3: {
      bucket: 'asset.engage.agis.inator.cloud',
      region: 'eu-west-1'
    },
    production: true
  }
};
